<script>
import APIStore from '@school/services/API/Store';

export default {
  name: 'Activation',
  head: {
    title: 'Activation',
  },
  data() {
    return {
      isLinkValid: true,
    };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
  },
  created() {
    const { query } = this.$route;

    if (!query.email || !query.t) {
      this.isLinkValid = false;
      return;
    }

    APIStore.verifyEmail(query)
      .then(() => {
        if (this.authCustomer) {
          this.$store.dispatch('auth/fetch');
        }

        this.$buefy.dialog.alert(
          'Votre compte est activé ! Vous pouvez désormais vous connecter à votre espace.',
        );
      })
      .catch((error) => this.$errorHandlers.axios(error))
      .finally(() => {
        this.authCustomer
          ? this.$router.push({ name: 'dashboard' })
          : this.$router.push({ name: 'login' });
      });
  },
};
</script>

<template>
  <section class="page section content is-large has-text-centered">
    <h1>Activation de votre compte</h1>
    <div v-if="isLinkValid" class="page_loading mt-10 mx-auto">
      <b-skeleton :count="3" size="is-large" />
    </div>
    <div v-else>
      <p>
        Ce lien d'activation n'existe pas. <br>
        Avez-vous bien copié le lien d'activation qui vous a été envoyé par email ?
      </p>

      <p>
        En cas de soucis, vous pouvez nous contactez par courriel à l'adresse
        <strong>allo@teachizy.fr</strong>,
        <br>
        en nous précisant l'adresse email associée à votre compte.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page {
  &_loading {
    width: 50%;
  }
}
</style>
